import Property from './Property'
import Resolver from './Resolver'

function CreateProxyDirectoryProperty (property, originalProperty, endPoint) {
  let proxyDir = new Proxy(property, {
    get (obj, prop) {
      if (prop !== 'children') { return obj[prop] }

      return originalProperty.Children.map(child => {
        if (child.Type === 0 || child.Type === 19) {
          return CreateProxyDirectoryProperty(
            new Property(child.Name, 'directory', 'Access `children` programatically to load children', proxyDir),
            child,
            endPoint
          )
        } else if (child.Meta.sha512) {
          return CreateImageProxyProperty(
            new Property(child.Name, 'image', 'Access `children` programatically to load children', proxyDir),
            child,
            child.Meta.sha512,
            endPoint
          )
        }
      })
    }
  })

  return proxyDir
}

function CreateImageProxyProperty (property, originalProperty, sha512, endPoint) {
  property.sha512 = sha512

  let proxyImg = null
  proxyImg = new Proxy(property, {
    get (obj, prop) {
      if (prop !== 'children') { return obj[prop] }

      if (originalProperty.realImg) { return originalProperty.realImg.children }

      if (originalProperty.realImgFetch) { return originalProperty.realImgFetch }

      if (!originalProperty.fetchArrayBuffer) {
        originalProperty.fetchArrayBuffer = fetch(`${endPoint}/data/${sha512}.img`)
          .then(res => res.arrayBuffer())

        originalProperty.fetchArrayBuffer.catch(err => {
          console.log('Error fetching image', sha512, err)
          originalProperty.fetchArrayBuffer = null
          originalProperty.getImage = null
          originalProperty.realImgFetch = null
        })
      }

      if (!originalProperty.getImage) {
        originalProperty.getImage = originalProperty.fetchArrayBuffer.then(res => Resolver.Image(property.name, res, property.parent))

        originalProperty.getImage.catch(err => {
          console.log('Error parsing image', err)
          originalProperty.getImage = null
          originalProperty.realImgFetch = null
        })
      }

      originalProperty.realImgFetch = originalProperty.getImage.then(realImg => {
        originalProperty.realImg = realImg
        originalProperty.realImg.sha512 = property.sha512
        return realImg.children
      })

      return originalProperty.realImgFetch
    }
  })

  return proxyImg
}

export default class WZFactory {
  constructor (endPoint) {
    this._endPoint = endPoint
  }

  Endpoint() {
    if ((typeof this._endPoint) === 'function') return this._endPoint()
    else return this._endPoint
  }

  GetWZ (region, version) {
    const wzKey = `wz-${region}-${version}`

    if (this[wzKey]) return this[wzKey]

    this[wzKey] = fetch(`${this.Endpoint()}/json/${region}/${version}/packages.json`)
      .then(res => res.json())
      .then(res => {
        const dataWz = res.find(p => p.MainDirectory.Name === 'Data')
        const rootNode = new Property(`${region}/${version}`, 'wz', null, null)

        rootNode.children = (dataWz ? dataWz.MainDirectory.Children : res)
          .filter(p => !p.Type)
          .map(p => {
            return CreateProxyDirectoryProperty(
              new Property(p.Name || p.MainDirectory.Name, 'directory', 'Access `children` programatically to load children', rootNode),
              p.Type === 0 ? p : p.MainDirectory,
              this.Endpoint()
            )
          })

        return rootNode
      })

    return this[wzKey]
  }

  GetWZFromImages (region, version) {
    const wzKey = `wz-images-${region}-${version}`

    if (this[wzKey]) return this[wzKey]

    this[wzKey] = fetch(`${this.Endpoint()}/json/${region}/${version}/images.json`)
      .then(res => res.json())
      .then(images => {
        let imagePaths = Object.keys(images)
        let directoryLookup = { }

        return imagePaths.reduceRight((total, path) => {
          let fileName = path.substr(path.lastIndexOf('/') + 1)
          let directory = path.substr(0, path.length - fileName.length - 1)

          let directoryNode = directoryLookup[directory]
          if (!directoryNode) {
            let pathParts = directory.split('/')
            directoryNode = pathParts.reduce((current, pathPart) => {
              let resolvesTo = current.children.find(child => child.name === pathPart)
              if (!resolvesTo) {
                resolvesTo = new Property(pathPart, 'directory', [], current)
                current.children.push(resolvesTo)
              }
              return resolvesTo
            }, total)

            directoryLookup[directory] = directoryNode
          }

          let imgProp = new Property(fileName, 'image', null, directoryNode)
          let proxyImg = CreateImageProxyProperty(imgProp, {}, images[path], this.Endpoint())

          directoryNode.children.push(proxyImg)

          return total
        }, new Property(`${region}/${version}`, 'wz', [], null))
      })

    return this[wzKey]
  }

  resolve (region, version, path) {
    return this.GetWZ(region, version)
      .then(res => res.resolve(path))
  }
}
