import { TranslateCanvasValue } from './TranslateCanvasValue'
export class MapLayer {
  constructor (map, mapWz, layerNode, $engine) {
    this.loading = Promise.all([
      layerNode.resolve('info').then(this.ParseInfo.bind(this))
        .catch(err => {
          console.error(`Couldn't load info from ${layerNode} -- ${err}`)
        }),
      layerNode.resolve('tile').then(this.ParseTiles.bind(this))
        .catch(err => {
          console.error(`Couldn't load tiles from ${layerNode} -- ${err}`)
        }),
      layerNode.resolve('obj').then(this.ParseObjects.bind(this))
        .catch(err => {
          console.error(`Couldn't load objects from ${layerNode} -- ${err}`)
        })
    ]).then(() => this.LoadCanvases(map, mapWz, $engine))
  }
  LoadCanvases (map, mapWz, $engine) {
    console.log('Loading canvases')
    return Promise.all([
      Promise.all(this.objects.map(obj => mapWz
        .resolve(`Obj/${obj.path}`)
        .then(objCanvas => objCanvas || (mapWz.resolve(`../Map2/Obj/${obj.path}`)))
        .then(objCanvas => objCanvas || (mapWz.resolve(`../Map002/Obj/${obj.path}`)))
        .then(objCanvas => objCanvas || (mapWz.resolve(`../Map001/Obj/${obj.path}`)))
        .then(objCanvas => {
          obj.canvasNode = objCanvas
          return TranslateCanvasValue(map, obj, $engine)
        }))),
      Promise.all(this.tiles.map(obj => mapWz
        .resolve(`Tile/${obj.path}`)
        .then(objCanvas => objCanvas || (mapWz.resolve(`../Map2/Tile/${obj.path}`)))
        .then(objCanvas => objCanvas || (mapWz.resolve(`../Map002/Tile/${obj.path}`)))
        .then(objCanvas => objCanvas || (mapWz.resolve(`../Map001/Tile/${obj.path}`)))
        .then(objCanvas => {
          obj.canvasNode = objCanvas
          return TranslateCanvasValue(map, obj, $engine)
        })))
    ])
  }
  ParseObjects (objectsNode) {
    if (!objectsNode) {
      this.objects = []
      return
    }
    this.objects = objectsNode.children.map(objectNode => {
      const result = objectNode.children.reduceRight((obj, prop) => {
        obj[prop.name] = prop.value
        return obj
      }, {})
      result.path = `${result.oS}.img/${result.l0}/${result.l1}/${result.l2}`.replace(' ', '')
      result.secondZ = result.zM
      result.flipX = result.f
      if (result.quest) {
        result.quest = result.quest.map(c => c.name)
      }
      result.visible = !result.quest && !result.tags && !result.groupName
      return result
    })
    this.objects.sort((a, b) => {
      if (a.front && !b.front) {
        return 10000 - b.z
      } else if (b.front && !a.front) {
        return a.z - 10000
      } else if (a.front && b.front) {
        return 0
      } else {
        return a.z - b.z
      }
    })
  }
  ParseTiles (tilesNode) {
    if (!tilesNode) {
      this.tiles = []
      return
    }
    this.tiles = tilesNode.children.map(tileNode => {
      const tile = {}
      tileNode.children.forEach(prop => {
        tile[prop.name] = prop.value
      })
      tile.path = `${this.info.tS}.img/${tile.u}/${tile.no}`.replace(' ', '')
      tile.flipX = tile.f
      tile.visible = true
      return tile
    })
  }
  ParseInfo (infoNode) {
    this.info = {}
    if (!infoNode) { return }
    infoNode.children.forEach(prop => {
      this.info[prop.name] = prop.value
    })
  }
  Render ($engine, left, top, right, bottom) {
    this.objects.forEach(tile => {
      if (tile.renderable && tile.visible) {
        tile.renderable.Render($engine, left, top, right, bottom)
      }
    })
    this.tiles.forEach(tile => {
      if (tile.renderable && tile.visible) {
        tile.renderable.Render($engine, left, top, right, bottom)
      }
    })
  }
  Update (delta) {
    this.objects.forEach(tile => {
      if (tile.renderable && tile.visible) {
        tile.renderable.Update(delta)
        tile.renderable.flipX = tile.flipX
      }
    })
    this.tiles.forEach(tile => {
      if (tile.renderable && tile.visible) {
        tile.renderable.flipX = tile.flipX
        tile.renderable.Update(delta)
      }
    })
  }
}
