<template>
  <div :class='{
    character: true,
    loaded,
    draggable,
    dragging: draggingEvent,
    hidden,
    development,
    drawerOpen: !!drawerOpen
  }' :style='style' @click='Click'>
    <img
      :src='characterAvatarUrl'
      @load='loaded = true'
      @error='ErrorLoading'
      :class='{
        loaded
      }'
      ref='characterImg'
      />
    <div class='dragging-coordinates' v-if='loaded'>
      {{ characterData.position.x }}, {{ characterData.position.y }}
    </div>
    <v-btn v-if='development && draggable' class='character-id' color='secondary'>
      {{ characterData.id }}
    </v-btn>
    <div class='controls' v-if='draggable && loaded' :class='{ focused }'>
      <v-speed-dial v-model='speedOpen' open-on-hover>
        <template v-slot:activator>
          <v-btn
            elevation="2"
            color="blue"
            fab
            dark
            v-model='speedOpen'
          >
            <v-icon dark v-if='!speedOpen'>mdi-pencil</v-icon>
            <v-icon dark v-else>mdi-close</v-icon>
          </v-btn>
        </template>
        <v-btn
          elevation="2"
          color="blue"
          fab
          dark
          @click='OpenItemSearch'
        >
          <v-icon dark>mdi-magnify</v-icon>
        </v-btn>
        <v-btn
          elevation="2"
          color="blue"
          fab
          dark
          @click='OpenInventory'
        >
          <v-icon dark>mdi-tshirt-crew</v-icon>
        </v-btn>
        <v-btn
          elevation="2"
          color="blue"
          fab
          dark
          @click='OpenMeta'
        >
          <v-icon dark>mdi-dots-horizontal</v-icon>
        </v-btn>
      </v-speed-dial>
    </div>
    <v-skeleton-loader type='image' v-if='!loaded' />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import SimDraggable from '@/Helpers/SimDraggable'
import DrawerNames from '@/Constants/Drawers'

export default {
  name: 'App.Character',

  data: function() {
    return {
      loaded: false,
      dragging: new SimDraggable(this),
      speedOpen: false,
      draggingEvent: false,
      error: false,
      retryCount: 0
    }
  },

  props: {
    id: Number,
    character: Object,
    linkType: String,
    draggable: Boolean
  },

  mounted() {
    if (this.draggable) {
      console.log('Initializing Dragging')
      this.dragging.init(this.$parent.$el, this.$el)
    }
  },

  destroyed() {
    if (this.draggable) {
      console.log('Unbinding dragging')
      this.dragging.destroy()
    }
  },

  methods: {
    ...mapActions(['SetEntityFocus', 'OpenDrawer', 'SetEntity']),

    SelectDrawer(e, drawer) {
      e.preventDefault()

      this.$nextTick(() => {
        this.OpenDrawer(drawer)
      })
    },

    Click(e) {
      if (e.defaultPrevented || !this.draggable) return

      e.preventDefault()
      this.SetEntityFocus(this.characterId)

      return false
    },

    Move(x, y) {
      const character = {
        ...this.characterData
      }

      character.position = {
        x: character.position.x - Math.floor(x),
        y: character.position.y - Math.floor(y)
      }

      if (Number.isNaN(character.position.x) || Number.isNaN(character.position.y))
        return

      this.SetEntity(character)
    },

    ErrorLoading(e) {
      console.warn('Error loading character', e)
      if (this.retryCount < 3)
        this.retryCount++
      else
        this.error = true
    },

    OpenItemSearch(e) {
      this.SelectDrawer(e, DrawerNames.Character.Edit.ItemSearch)
    },

    OpenInventory(e) {
      this.SelectDrawer(e, DrawerNames.Character.Edit.Equipped)
    },

    OpenMeta(e) {
      this.SelectDrawer(e, DrawerNames.Character.Edit.Meta)
    }
  },

  computed:{
    ...mapState(['apiRoot', 'focusedEntityId', 'region', 'version', 'background', 'drawerOpen', 'development']),
    ...mapGetters(['characters']),

    style() {
      if (!this.draggable) return {}

      return {
        left: this.x + 'px',
        top: this.y + 'px'
      }
    },

    focused() {
      return this.characterId === this.focusedEntityId
    },

    characterData() {
      if (this.character) return this.character

      return this.characters[this.characterId]
    },

    characterId() {
      if (this.character) return this.character.id

      if (this.id) return this.id

      return this.focusedEntityId
    },

    characterItemEntries() {
      var items = Object.values(this.characterData.selectedItems)
        .filter(item => item.id && (item.id < 20000 || item.visible === undefined || item.visible))
        .map(item => {
          let itemEntry = {
            itemId: Number(item.id)
          }

          let isFace = (item.id >= 20000 && item.id < 30000) || (item.id >= 50000 && item.id < 60000)
          let isFaceAcc = (item.id >= 1010000 && item.id < 1020000)
          if (isFace || isFaceAcc) itemEntry.animationName = this.characterData.emotion
          itemEntry.region = item.region || this.region
          itemEntry.version = item.version || this.version
          if (item.hue) itemEntry.hue = item.hue
          if (item.saturation !== 1) itemEntry.saturation = item.saturation
          if (item.contrast !== 1) itemEntry.contrast = item.contrast
          if (item.brightness !== 1) itemEntry.brightness = item.brightness
          if (item.alpha !== 1) itemEntry.alpha = item.alpha
          if (item.islot) itemEntry.islot = item.islot
          if (item.vslot) itemEntry.vslot = item.vslot
          if (item.equipFrame) itemEntry.equipFrame = item.equipFrame
          if (item.disableEffect) itemEntry.disableEffect = item.disableEffect
          if (item.glow) itemEntry.glow = item.glow
          if (item.grayscale) itemEntry.grayscale = item.grayscale
          if (item.invert) itemEntry.invert = item.invert
          if (item.oilPaint) itemEntry.oilPaint = item.oilPaint
          if (item.sepia) itemEntry.sepia = item.sepia

          return itemEntry
        })

        if (!items.find(item => item.itemId < 10000))
          items.push({itemId: 2000, region: this.region, version: this.version, alpha: 0})
        if (!items.find(item => item.itemId < 20000 && item.itemId >= 10000))
          items.push({itemId: 12000, region: this.region, version: this.version, alpha: 0})

        return items
    },

    characterItemEntriesPayload() {
      let itemEntriesPayload = JSON.stringify(this.characterItemEntries)
      itemEntriesPayload = encodeURIComponent(
        itemEntriesPayload.substr(1, itemEntriesPayload.length - 2)
      )

      return itemEntriesPayload
    },

    linkParameters() {
      let {
        mercEars,
        illiumEars,
        highFloraEars,
        zoom,
        name,
        flipX,
        includeBackground,
        animating
      } = this.characterData

      const props = {
        'showears': mercEars,
        'showLefEars': illiumEars,
        'showHighLefEars': highFloraEars,
        'resize': zoom,
        'name': name,
        'flipX': flipX
      }

      const backgroundColor = this.background || defaultBackground
      if (backgroundColor && animating && includeBackground && backgroundColor.alpha) {
        const bgColorText = `${backgroundColor.rgba.r},${backgroundColor.rgba.g},${backgroundColor.rgba.b},${backgroundColor.rgba.a}`
        props.bgColor = bgColorText
      }

      Object.keys(props).forEach(key => {
        if (props[key] == null)
          delete props[key]
      })

      if (!this.draggable)
        delete props.resize

      return new URLSearchParams(props)
    },

    characterAvatarUrl() {
      if (!this.characterData) {
        return null
      }

      let {
        animating,
        action,
        frame
      } = this.characterData

      const url = [
        this.apiRoot,
        'character',
        this.characterItemEntriesPayload,
        action,
        animating ? 'animated' : frame
      ].join('/') + '?' + this.linkParameters.toString()

      return url
    },

    x: {
      get() { return this.characterData.position.x },
      set(value) { this.characterData.position.x = value }
    },

    y: {
      get() { return this.characterData.position.y },
      set(value) { this.characterData.position.y = value }
    },

    hidden() { return !this.characterData.visible }
  },

  watch: {
    characterAvatarUrl() {
      this.loaded = false
      this.error = false
      this.retryCount = 0
    },
  }
}

const defaultBackground = {
  "rgba":{
    "r":0,
    "g":0,
    "b":0,
    "a":0
  }
}
</script>

<style lang="scss" scoped>
.character {
  position: absolute;
  display: inline-flex;
  justify-content: center;
  align-items: flex-end;

  &.hidden.draggable {
    display: none;
  }

  &:not(.draggable) {
    min-height: 100px;
  }

  &.draggable {
    &:not(.loaded) {
      width: 100px;
    }

    img {
      top: 0;
    }

    &.drawerOpen .controls.focused {
      opacity: 0;
      visibility: hidden;
    }

    .controls {
      position: absolute;
      left: 50px;
      top: 0;
      transition: all 0.5s;
      opacity: 0;
      visibility: hidden;

      &.focused {
        opacity: 1;
        visibility: visible;
      }

      > *:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }

  .dragging-coordinates {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    padding: 4px 8px;
    white-space: nowrap;
    border-radius: 4px;
    color: white;
    opacity: 0;
    transition: all 0.25s;
  }

  .character-id {
    position: absolute;
    bottom: 50px;
  }

  &.dragging .dragging-coordinates, &.development.draggable .dragging-coordinates {
    opacity: 1;
  }

  img {
    visibility: hidden;
    position: absolute;

    &.loaded {
      visibility: visible;
    }
  }
}

.v-skeleton-loader {
  width: 100%;
  height: 100%;

  max-height: 100px;
}
</style>
