export default class SimDraggable {
  constructor(that) {
    this.that = that

    this.Bounded = {
      Move: this.Move.bind(this),
      Down: this.Down.bind(this),
      Up: this.Up.bind(this)
    }

    document.addEventListener('mouseup', this.Bounded.Up)
  }

  init(el, target) {
    this.$el = el
    this.target = target || el

    this.$el.addEventListener('mousemove', this.Bounded.Move)
    this.$el.addEventListener('touchmove', this.Bounded.Move)

    this.$el.addEventListener('mousedown', this.Bounded.Down)
    this.$el.addEventListener('touchstart', this.Bounded.Down)

    this.$el.addEventListener('mouseup', this.Bounded.Up)
    this.$el.addEventListener('touchend', this.Bounded.Up)
  }

  destroy() {
    document.removeEventListener('mouseup', this.Bounded.Up)

    if (this.$el) {
      this.$el.removeEventListener('mousemove', this.Bounded.Move)
      this.$el.removeEventListener('touchmove', this.Bounded.Move)

      this.$el.removeEventListener('mousedown', this.Bounded.Down)
      this.$el.removeEventListener('touchstart', this.Bounded.Down)


      this.$el.removeEventListener('mouseup', this.Bounded.Up)
      this.$el.removeEventListener('touchend', this.Bounded.Up)
    }
  }

  ProcessMovement(e) {
    const delta = {
      x: this.that.draggingEvent.x - e.clientX,
      y: this.that.draggingEvent.y - e.clientY
    }

    this.that.Move(
      Math.floor(delta.x),
      Math.floor(delta.y)
    )

    // this.that.x -= Math.floor(delta.x)
    // this.that.y -= Math.floor(delta.y)

    let oldEvent = this.that.draggingEvent
    if (oldEvent && oldEvent.original) oldEvent = oldEvent.original

    this.that.draggingEvent = {
      x: e.clientX,
      y: e.clientY,
      processed: true,
      original: oldEvent
    }
  }

  Move(e) {
    if (!this.that.draggingEvent) return

    e.preventDefault()

    if (e.type === 'mousemove')
      this.ProcessMovement(e)
    else if (e.type === 'touchmove')
      for (let i = 0; i < e.touches.length; ++i)
        this.ProcessMovement(e.touches[i])
  }

  Up(e) {
    const originalDown = this.that.draggingEvent
    this.that.draggingEvent = false

    if (!originalDown) return
    if (originalDown.processed) {
      const originalStart = originalDown.original
      const elapsedTime = Date.now() - originalStart.timestamp

      if (elapsedTime > 250) return

      console.log(`Elapsed time: ${elapsedTime}, assuming intended to click`)
    }

    // Simulate click for Veutify speed-dial for character
    if (e.type === 'touchend') {
      e.target.click(e)
    }

    e.preventDefault()
  }

  Down(e) {
    if (e.defaultPrevented) return

    let currentTarget = e.target
    while (currentTarget != this.target && !currentTarget.classList.contains('draggable') && currentTarget != this.$el && currentTarget != null) {
      currentTarget = currentTarget.parentNode
    }

    if (currentTarget != this.target) {
      return
    }

    e.preventDefault()

    if (e.type === 'mousedown') {
      this.that.draggingEvent = {
        x: e.clientX,
        y: e.clientY
      }
    }

    if (e.type === 'touchstart') {
      this.that.draggingEvent = {
        x: e.targetTouches[0].clientX,
        y: e.targetTouches[0].clientY,
        timestamp: Date.now()
      }

      if (e.targetTouches.length > 1)
        for (let i = 0; i < e.targetTouches.length; ++i)
          this.ProcessMovement(e.targetTouches[i])
    }
  }
}