import Vue from 'vue'
import Vuex from 'vuex'

const defaultBackground = {
  "alpha": 0,
  "hex": "#000000",
  "hexa": "#00000000",
  "hsla": {
      "h": 0,
      "s": 0,
      "l": 0,
      "a": 0
  },
  "hsva": {
      "h": 0,
      "s": 0,
      "v": 0,
      "a": 0
  },
  "hue": 0,
  "rgba": {
      "r": 0,
      "g": 0,
      "b": 0,
      "a": 0
  }
}

Vue.use(Vuex)
const store = new Vuex.Store({
  state: {
    region: 'GMS',
    version: '217',
    apiRoot: 'https://maplestory.io/api',
    storeRoot: 'https://store.maplestory.dev/api',
    focusedEntityId: null,
    drawerOpen: null,
    itemListCategory: 'Character',
    itemListSubCategory: 'Face',
    itemListSearch: '',
    background: defaultBackground,
    development: false,
    camera: {
      x: 0,
      y: 0
    },
    addPetOpen: false,
    addMobOpen: false,
    addNPCOpen: false,
    entities: {},
    ServiceWorkerStatus: {
      Ready: false,
      Registered: false,
      Cached: false,
      UpdateFound: false,
      Updated: false,
      Offline: false,
      Error: null
    },
    PWA: {
      online: navigator.onLine,
      firstInstall: false,
      isInstalledApp: !!window.matchMedia('(display-mode: standalone)').matches,
      BeforeInstallPrompt: null,
      language: navigator.language,
      languages: navigator.languages
    }
  },
  getters: {
    api: state => `${state.apiRoot}/${state.region}/${state.version}`,
    currentCharacter: (state, getters) => getters.characters[state.focusedEntityId],
    entitiesByType: state => type => Object.values(state.entities)
    .filter(entity => entity.type === type)
    .reduce((total, current) => {
      total[current.id] = current
      return total
    }, {}),
    characters: (state, getters) => getters.entitiesByType('character'),
    pets: (state, getters) => getters.entitiesByType('pet')
  },
  actions: {
    SetEntity({ commit }, entity) {
      commit('SET_ENTITY', entity)
    },

    OpenDrawer({ commit }, drawer) {
      if (drawer === false) return

      commit('OPEN_DRAWER', drawer)
    },

    SetEntityFocus({ commit }, entityId) {
      commit('SET_ENTITY_FOCUS', entityId)
    },

    SetCamera({ commit }, camera) {
      commit('SET_CAMERA', camera)
    },

    SetCameraOn({ commit, state }, focusOn) {
      const entity = state.entities[focusOn]
      commit('SET_ENTITY_FOCUS', focusOn)
      commit('SET_CAMERA', {
        x: -entity.position.x,
        y: -entity.position.y
      })
    },

    AddCharacter({ commit, state }, characterData) {
      if (characterData && characterData.type === 'character') {
        const character = {
          ...characterData
        }

        if (state.entities[characterData.id])
          character.id = Date.now()

        character.position = {
          x: characterData.position.x + 100,
          y: characterData.position.y
        }

        commit('SET_ENTITY', character)

        return
      }

      const character = {
        ...characterTemplate,
        id: Date.now(),
        position: {
          x: -state.camera.x,
          y: -state.camera.y
        }
      }

      character.selectedItems.Body = {
        "name": "Body",
        "noIcon": true,
        "id": 2000,
        "region": state.region,
        "version": state.version,
        "typeInfo": {
          "overallCategory": "Character",
          "category": "Character",
          "subCategory": "Body",
          "lowItemId": 2000,
          "highItemId": 2999
        }
      }
      character.selectedItems.Head = {
        "name": "Head",
        "noIcon": true,
        "id": 12000,
        "region": state.region,
        "version": state.version,
        "typeInfo": {
          "overallCategory": "Character",
          "category": "Character",
          "subCategory": "Head",
          "lowItemId": 12000,
          "highItemId": 12999
        }
      }

      commit('SET_ENTITY', character)
    },

    DeleteEntity({ commit }, id) {
      commit('DELETE_ENTITY', id)
    },

    ApplySetting({ commit }, props) {
      commit('APPLY_SETTING', props)
    },

    // ServiceWorker stuff:
    ServiceWorkerReady ({ commit }) {
      commit('UPDATE_SERVICE_WORKER_STATUS', { Ready: true })
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    ServiceWorkerRegistered ({ commit }, reg) {
      commit('UPDATE_SERVICE_WORKER_STATUS', { Registered: reg })
      console.log('Service worker has been registered.', reg)
    },
    ServiceWorkerCached ({ commit }) {
      commit('UPDATE_SERVICE_WORKER_STATUS', { Cached: true })
      console.log('Content has been cached for offline use.')
    },
    ServiceWorkerUpdateFound ({ commit }) {
      commit('UPDATE_SERVICE_WORKER_STATUS', { UpdateFound: true })
      console.log('New content is downloading.')
    },
    ServiceWorkerUpdated ({ commit }) {
      commit('UPDATE_SERVICE_WORKER_STATUS', { Updated: true })
      console.log('New content is available; please refresh.')
    },
    ServiceWorkerOffline ({ commit }) {
      commit('UPDATE_SERVICE_WORKER_STATUS', { Offline: true })
      console.log('No internet connection found. App is running in offline mode.')
    },
    ServiceWorkerError ({ commit }, error) {
      commit('UPDATE_SERVICE_WORKER_STATUS', { Error: error })
      console.error('Error during service worker registration:', error)
    },
    BeforeInstallPrompt({ commit }, BeforeInstallPrompt) { commit('UPDATE_PWA', { BeforeInstallPrompt }) },
    AppInstalled({ commit }, firstInstall) { commit('UPDATE_PWA', { firstInstall }) },
    NetworkStatus({ commit }, online) { commit('UPDATE_PWA', { online }) },
    UpdateLanguages({ commit }) {
      commit('UPDATE_PWA', {
        language: navigator.language,
        languages: navigator.languages
      })
    }
  },
  mutations: {
    APPLY_SETTING(state, payload) {
      Object.keys(payload).forEach(key => {
        Vue.set(state, key, payload[key])
      })
    },

    UPDATE_SERVICE_WORKER_STATUS(state, payload) {
      const status = {
        ...state.ServiceWorkerStatus
      }

      Object.keys(payload).forEach(key => {
        Vue.set(status, key, payload[key])
      })

      Vue.set(state, 'ServiceWorkerStatus', status)
    },

    UPDATE_PWA(state, payload) {
      const status = {
        ...state.PWA
      }

      Object.keys(payload).forEach(key => {
        Vue.set(status, key, payload[key])
      })

      Vue.set(state, 'PWA', status)
    },

    OPEN_DRAWER(state, payload) {
      Vue.set(state, 'drawerOpen', payload)
    },

    SET_ENTITY_FOCUS(state, payload) {
      Vue.set(state, 'focusedEntityId', payload)
    },

    DELETE_ENTITY(state, payload) {
      Vue.set(state, 'drawerOpen', null)
      Vue.set(state, 'focusedEntityId', null)
      Vue.delete(state.entities, payload)
    },

    SET_ENTITY(state, payload) {
      Vue.set(
        state.entities,
        payload.id,
        payload
      )
    },

    SET_CAMERA(state, payload) {
      Vue.set(
        state,
        'camera',
        payload
      )
    }
  }
})

const characterTemplate = {
  "type": "character",
  "action": "stand1",
  "emotion": "default",
  "skin": 2000,
  "zoom": 1,
  "frame": 0,
  "mercEars": false,
  "illiumEars": false,
  "selectedItems": { },
  "visible": true,
  "position": {
    "x": 0,
    "y": 0
  },
  "fhSnap": true,
  "flipX": false,
  "name": "",
  "includeBackground": true
}

export default store