class Renderable {
  constructor (frameElements, x, y) {
    this.frames = frameElements
    this.frame = frameElements[0]
    this.frameIndex = 0
    this.delay = this.frame.delay
    this.x = x
    this.y = y
  }

  Render ($engine, left, top, right, bottom) {
    if (!this.frame) {
      console.warn('No frame, can\'t render')
      return
    }

    const frameLeft = this.x - (this.flipX ? -this.frame.origin.x : this.frame.origin.x)
    const frameTop = this.y - this.frame.origin.y
    const frameRight = frameLeft + (this.flipX ? -this.frame.width : this.frame.width)
    const frameBottom = frameTop + this.frame.height

    const leftWithinBounds = frameLeft >= left && frameLeft <= right
    const rightWithinBounds = frameRight >= left && frameRight <= right
    const xOversized = frameLeft <= left && frameRight >= right
    const XWithinBounds = leftWithinBounds || rightWithinBounds || xOversized

    const topWithinBounds = frameTop >= top && frameTop <= bottom
    const bottomWithinBounds = frameBottom >= top && frameBottom <= bottom
    const yOversized = frameTop <= top && frameBottom >= bottom
    const YWithinBounds = topWithinBounds || bottomWithinBounds || yOversized

    if (!XWithinBounds || !YWithinBounds) return

    $engine.drawImage(this.frame, frameLeft, frameTop, this.flipX)
  }

  AdvanceFrame () {
    this.frameIndex = (this.frameIndex + 1) % this.frames.length
    this.frame = this.frames[this.frameIndex]
    this.delay = this.frame.delay
  }

  Update (delta) {
    this.delay -= delta
    if (this.delay <= 0) { this.AdvanceFrame() }
  }
}

const bodyElement = document.querySelector('body')

export default class RendererEngine {
  constructor (ctx) {
    this.cookie = Date.now()
    this.program = null
    this.positionLocation = null
    this.texcoordLocation = null
    this.matrixLocation = null
    this.textureLocation = null
    this.positionBuffer = null
    this.positions = [
      0, 0,
      0, 1,
      1, 0,
      1, 0,
      0, 1,
      1, 1
    ]
    this.texcoordBuffer = null
    this.texcoords = [
      0, 0,
      0, 1,
      1, 0,
      1, 0,
      0, 1,
      1, 1
    ]

    this.ctx = ctx
    // setup GLSL program
    if (ctx instanceof WebGLRenderingContext) {
      const gl = ctx
      // eslint-disable-next-line no-undef
      this.program = webglUtils.createProgramFromScripts(gl, ['drawImage-vertex-shader', 'drawImage-fragment-shader'])

      // look up where the vertex data needs to go.
      this.positionLocation = gl.getAttribLocation(this.program, 'a_position')
      this.texcoordLocation = gl.getAttribLocation(this.program, 'a_texcoord')

      // lookup uniforms
      this.matrixLocation = gl.getUniformLocation(this.program, 'u_matrix')
      this.textureLocation = gl.getUniformLocation(this.program, 'u_texture')

      // Create a buffer.
      this.positionBuffer = gl.createBuffer()
      gl.bindBuffer(gl.ARRAY_BUFFER, this.positionBuffer)

      // Put a unit quad in the buffer
      gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(this.positions), gl.STATIC_DRAW)

      // Create a buffer for texture coords
      this.texcoordBuffer = gl.createBuffer()
      gl.bindBuffer(gl.ARRAY_BUFFER, this.texcoordBuffer)

      // Put texcoords in the buffer
      gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(this.texcoords), gl.STATIC_DRAW)
    }
  }

  preRender (ctx) {
    // this matirx will convert from pixels to clip space
    ctx.useProgram(this.program)
    ctx.bindBuffer(ctx.ARRAY_BUFFER, this.positionBuffer)
    ctx.enableVertexAttribArray(this.positionLocation)
    ctx.vertexAttribPointer(this.positionLocation, 2, ctx.FLOAT, false, 0, 0)
    ctx.bindBuffer(ctx.ARRAY_BUFFER, this.texcoordBuffer)
    ctx.enableVertexAttribArray(this.texcoordLocation)
    ctx.vertexAttribPointer(this.texcoordLocation, 2, ctx.FLOAT, false, 0, 0)
    // Tell the shader to get the texture from texture unit 0
    ctx.uniform1i(this.textureLocation, 0)
  }

  drawImage (piece, x, y, flipX) {
    const ctx = this.ctx

    if (ctx instanceof CanvasRenderingContext2D) {
      ctx.drawImage(piece.canvas, x, y)
    } else if (ctx instanceof WebGLRenderingContext) {
      const gl = ctx

      gl.bindTexture(gl.TEXTURE_2D, piece.texture)

      // this matrix will translate our quad to dstX, dstY
      // eslint-disable-next-line no-undef
      let drawMatrix = m4.translate(
        this.matrix,
        x - this.cameraX,
        y - this.cameraY,
        0
      )

      // this matrix will scale our 1 unit quad
      // from 1 unit to texWidth, texHeight units
      // eslint-disable-next-line no-undef
      drawMatrix = m4.scale(drawMatrix, piece.width, piece.height, 1)

      if (flipX) {
        // eslint-disable-next-line no-undef
        drawMatrix = m4.scale(drawMatrix, -1, 1, 1)
      }

      // Set the matrix.
      gl.uniformMatrix4fv(this.matrixLocation, false, drawMatrix)

      // draw the quad (2 triangles, 6 vertices)
      gl.drawArrays(gl.TRIANGLES, 0, 6)
    }
  }

  CreateRenderable (frames, x, y) {
    return Promise.all(frames.filter(c => c && c.type === 'canvas')
      .map(canvasNode => {
        if (!canvasNode || !canvasNode.value) return null
        return Promise.all([
          canvasNode.resolve('origin'),
          canvasNode.resolve('delay'),
          canvasNode.resolve('z')
        ]).then(([origin, delay, z]) =>
          this.GetRenderableFrameFromCanvas(canvasNode, origin, delay, z)
        )
      })
    ).then(canvasElements => {
      canvasElements = canvasElements.filter(c => c)
      if (canvasElements && canvasElements.length) {
        return new Renderable(canvasElements, x, y)
      }
    })
  }

  GetRenderableFrameFromCanvas (canvasNode, origin, delay, z) {
    if (origin) origin = origin.value
    else {
      origin = {x: 0, y: 0}
    }
    if (delay != null) delay = delay.value
    else delay = 100
    return new Promise((resolve) => {
      if (canvasNode.canvas || canvasNode.texture) {
        if (canvasNode.cookie === this.cookie) {
          resolve({
            canvasNode,
            origin,
            delay,
            canvas: canvasNode.canvas,
            z,
            texture: canvasNode.texture,
            width: canvasNode.width,
            height: canvasNode.height
          })
          return
        } else {
          console.log('Invalid cookie, resetting')
          delete canvasNode.cookie
          delete canvasNode.canvas
          delete canvasNode.texture
        }
      }

      let canvasData = this.CreateRenderableFrame(
        canvasNode.value,
        canvasNode.width,
        canvasNode.height
      )

      resolve({
        canvasNode,
        origin,
        delay,
        z,
        width: canvasNode.width,
        height: canvasNode.height,
        ...canvasData
      })
    })
  }

  CreateRenderableFromMeta (metaDetails, x, y, z) {
    let allFrames = metaDetails.map(details => {
      let { meta, img, canvas, byteData } = details
      let { width, height } = img
      let origin = { x:0, y:0 }
      let delay = 500

      if (meta.meta) {
        if (meta.meta.Origin)
          origin = { x: meta.meta.Origin.X, y: meta.meta.Origin.Y }

        if (meta.meta.delay != null)
          delay = meta.meta.delay
      }

      if (!canvas) {
        let canvas = document.createElement('canvas')
        canvas.style.display = 'none'
        canvas.width = width
        canvas.height = height
        bodyElement.appendChild(canvas)
        details.ctx = canvas.getContext('2d')
        details.ctx.drawImage(img, 0, 0)
        details.canvas = canvas
      }

      if (!byteData) {
        byteData = details.ctx.getImageData(0, 0, width, height)
        details.byteData = byteData
      }

      // If we don't have a texture AND we're in WebGL mode
      // otherwise, we already have the canvas, and that logic would just burn cycles
      if (!details.texture && this.ctx instanceof WebGLRenderingContext) {
        let canvasData = this.CreateRenderableFrame(byteData, width, height)
        details.texture = canvasData.texture
      }

      return {
        origin,
        delay,
        z,
        width,
        height,
        canvas: details.canvas,
        texture: details.texture
      }
    })

    allFrames = allFrames.filter(c => c)
    if (allFrames && allFrames.length) {
      return new Renderable(allFrames, x, y)
    }
  }

  CreateRenderableFrame(byteData, width, height) {
    if (this.ctx instanceof WebGLRenderingContext) {
      const gl = this.ctx

      const textureId = gl.createTexture()
      gl.bindTexture(gl.TEXTURE_2D, textureId)
      gl.pixelStorei(gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL, true)
      gl.texImage2D(
        gl.TEXTURE_2D,
        0,
        gl.RGBA,
        width,
        height,
        0,
        gl.RGBA,
        gl.UNSIGNED_BYTE,
        new Uint8Array(byteData.data)
      )

      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE)
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE)
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR)

      return { texture: textureId }
    } else if (this.ctx) {
      const canvasTarget = document.createElement('canvas')
      canvasTarget.style.display = 'none'
      canvasTarget.width = width
      canvasTarget.height = height
      bodyElement.appendChild(canvasTarget)
      const ctx = canvasTarget.getContext('2d')
      if (byteData) {
        ctx.putImageData(new ImageData(byteData, width, height), 0, 0)
      }

      return { canvas: canvasTarget }
    }
  }
}
