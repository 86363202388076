export function TranslateCanvasValue (map, target, $engine) {
  const { canvasNode } = target
  if (!canvasNode) { return }
  let canvasNodes = null

  if (target.animated) {
    console.warn('Animated')
  }

  if (canvasNode.type === 'canvas' && !target.animated) {
    canvasNodes = [canvasNode]
  } else {
    canvasNodes = canvasNode.children
  }
  return Promise.all(canvasNodes.map(c => c.resolve()))
    .then(canvasNodes => $engine.CreateRenderable(canvasNodes, target.x, target.y))
    .then(renderable => {
      target.renderable = renderable
      return renderable
    })
}
