<template>
  <v-speed-dial v-model='speedOpen' bottom right fixed class='app-speed'>
    <template v-slot:activator>
      <v-btn
        elevation="6"
        color="blue"
        fab
        dark
        v-model='speedOpen'
      >
        <v-icon dark v-if='!speedOpen'>mdi-plus</v-icon>
        <v-icon dark v-else>mdi-close</v-icon>
      </v-btn>
      <DialogFromAPI
        :API='API.Pets'
        setting='addPetOpen'
        title='Choose a pet'
        @select='SelectPet'
        :key='addPetOpen'
        v-if='addPetOpen'
      />
      <DialogFromAPI
        :API='API.NPCs'
        setting='addNpcOpen'
        title='Choose an NPC'
        @select='SelectNPC'
        :key='addNpcOpen'
        v-if='addNpcOpen'
      />
      <DialogFromAPI
        :API='API.Mobs'
        setting='addMobOpen'
        title='Choose a Monster'
        @select='SelectMob'
        :key='addMobOpen'
        v-if='addMobOpen'
      />
    </template>

    <v-btn elevation="2" color="primary" dark @click='AddCharacter'>
      Character
    </v-btn>
    <v-btn elevation="2" color="primary" dark @click='Apply("addPetOpen")'>
      Pet
    </v-btn>
    <v-btn elevation="2" color="primary" dark @click='Apply("addNpcOpen")'>
      NPC
    </v-btn>
    <v-btn elevation="2" color="primary" dark @click='Apply("addMobOpen")'>
      Monster
    </v-btn>
    <v-btn elevation="2" color="secondary" dark @click='Import'>
      Import
    </v-btn>
    <input type='file' @change='importFile' multiple ref='import' />
  </v-speed-dial>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import DialogFromAPI from './Generic/DialogFromAPI'
import API from '../API'
import Fixes from '../Store/Fixes'

export default {
  name: 'App.Speed',

  components: {
    DialogFromAPI
  },

  data: () => ({
    speedOpen: false,
    API
  }),

  methods: {
    ...mapActions(['AddCharacter', 'SetEntity', 'SetCamera', 'ApplySetting']),

    Apply(prop) {
      const props = { }
      props[prop] = Date.now()
      this.ApplySetting(props)
    },

    SelectPet(pet) {
      console.log('Selecting Pet: ', pet)

      const actions = Object.keys(pet.frameBooks).reduce((total, current) => {
        const frameCount = pet.frameBooks[current].length
        if (frameCount > 0) total[current] = frameCount
        return total
      }, {})

      this.SetEntity({
        type: 'pet',
        id: Date.now(),
        petId: pet.id,
        actions,
        action: pet.frameBooks['stand1'] ? 'stand1' : Object.keys(pet.frameBooks)[0],
        frame: 0,
        position: { x: -this.camera.x, y: -this.camera.y },
        region: this.region,
        version: this.version,
      })
    },

    SelectNPC(npc) {
      const actions = { ...npc.framebooks }

      let standAction = 'stand1'
      if (!actions[standAction]) standAction = 'stand'
      if (!actions[standAction]) standAction = 'fly'
      if (!actions[standAction]) standAction = 'say'
      if (!actions[standAction]) standAction = Object.keys(actions)[0]
      if (!standAction) throw new Error('Couldn\'t find a default action for NPC', npc)

      this.SetEntity({
        type: 'npc',
        id: Date.now(),
        npcId: npc.id,
        actions,
        action: standAction,
        frame: 0,
        position: { x: -this.camera.x, y: -this.camera.y },
        region: this.region,
        version: this.version,
      })
    },

    SelectMob(mob) {
      const actions = { ...mob.framebooks }

      let standAction = 'stand1'
      if (!actions[standAction]) standAction = 'stand'
      if (!actions[standAction]) standAction = 'fly'
      if (!actions[standAction]) standAction = 'say'
      if (!actions[standAction]) standAction = Object.keys(actions)[0]
      if (!standAction) throw new Error('Couldn\'t find a default action for mob', mob)

      this.SetEntity({
        type: 'mob',
        id: Date.now(),
        mobId: mob.id,
        actions,
        action: standAction,
        frame: 0,
        position: { x: -this.camera.x, y: -this.camera.y },
        region: this.region,
        version: this.version,
      })
    },

    Import() {
      this.$refs.import.click()
    },

    importFile(e) {
      let target = e.target
      let importAll = Array.prototype.map.call(target.files, file => {
        let extension = file.name.substr(file.name.lastIndexOf('.') + 1).toLowerCase()
        if (extension !== 'json') {
          console.warn('Not valid JSON file')
          return
        }

        return new Promise((res) => {
          let reader = new FileReader()
          reader.onload = (ev) => {
            let payload = ev.target.result
            let data = JSON.parse(payload)

            res(data)

            if (!data.id || data.type !== 'character' || !data.selectedItems) return

            data.id = Date.now()

            if (!Fixes.character(data, this.$store.state))
              throw new Error('Something went wrong trying to fix potential issues with the character', data)

            this.SetEntity(data)
            this.SetCamera({
              x: -data.position.x,
              y: -data.position.y
            })
          }

          reader.readAsText(file, 'UTF8')
        })
      })

      Promise.all(importAll).then(() => {
        target.value = ''
      })
    }
  },

  computed: {
    ...mapState(['addPetOpen', 'addMobOpen', 'addNpcOpen', 'camera', 'region', 'version'])
  }
}
</script>

<style lang="scss">
.app-speed {
  .v-speed-dial__list {
    align-items: flex-end;
  }

  input {
    display: none;
  }
}
</style>