<template>
  <div
    class='sim-container draggable'
    :style='ContainerStyle'
    ref='SimContainer'
    @click='Click'
    @mousedown='Down'
  >
    <div class='coordinates v-tooltip__content'>
    {{ -x }}, {{ -y }}
    </div>
    <div class='sim' :style='Style'>
      <Map :id='mapIdTest' v-if='mapIdTest != null' />

      <Character
        v-for='character in characters'
        :key='character.id'
        :id='character.id'
        draggable
        ref='characterEl' />

      <Entity
        v-for='entity in genericEntities'
        :key='entity.id'
        :id='entity.id'
        draggable
        ref='entityEl' />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import Character from './Character/index'
import Entity from './Entity/index'
import Map from './Map/Render'
import SimDraggable from '../Helpers/SimDraggable'
import DrawerNames from '@/Constants/Drawers'

export default {
  name: 'Sim',

  components: { Character, Entity, Map },

  data: function () {
    return {
      dragging: new SimDraggable(this)
    }
  },

  mounted() {
    this.dragging.init(this.$el)
  },

  destroyed() {
    this.dragging.destroy()
  },

  methods: {
    ...mapActions(['SetEntityFocus', 'SetCamera']),

    Click(e) {
      if (e.defaultPrevented) return

      e.preventDefault()
      this.SetEntityFocus()
    },

    Down(e) {
      if (e.currentTarget === e.target)
        this.SetEntityFocus()
    },

    Move(x, y) {
      this.SetCamera({
        x: this.x - x,
        y: this.y - y
      })
    }
  },

  computed: {
    ...mapState(['camera', 'background', 'drawerOpen', 'entities', 'mapIdTest']),
    ...mapGetters(['characters', 'pets']),

    genericEntities() {
      const generic = ['pet', 'mob', 'npc']
      return Object
        .values(this.entities)
        .filter(entity => generic.indexOf(entity.type) != -1)
    },

    IsCharacterEditDrawerOpen() {
      return Object.values(DrawerNames.Character.Edit)
        .indexOf(this.drawerOpen) != -1
    },

    Style() {
      const style = {
        top: `${this.y}px`,
        left: `${this.x}px`
      }

      if (this.IsCharacterEditDrawerOpen)
        style['z-index'] = 10

      return style
    },

    ContainerStyle() {
      if (this.background && this.background.alpha)
        return {
          background: this.background.hexa
        }
      return {
        'background-position-x': `${this.x}px`,
        'background-position-y': `${this.y}px`
      }
    },

    x() { return this.camera.x },
    y() { return this.camera.y }
  }
}
</script>

<style lang="scss" scoped>
.sim-container {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAACgCAMAAACsXRuGAAAADFBMVEWIiIjd3d3y8vLm5uZzdc63AAAAbklEQVR42u3ZIQ4AIAwDwI39/8/wAlTFxFUgm5ALmFZV51Iv52Vyx/7CzhdONr3/ylCgQIECBQoUKFCgQIECBQoUKFCgQIECBcrmccGe4qX4vqBAgQIFChQoUKBAgQIFChQoUKBAgQIFij3lX3gBXBBzc8FaGvIAAAAASUVORK5CYII=);
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .sim {
    position: relative;

    .character {
      cursor: pointer;
    }
  }
}

.coordinates {
  position: fixed;
  top: 90px;
  right: 32px;
  z-index: 15;
}
</style>