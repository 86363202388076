import { MapLayer } from './MapLayer'
import { TranslateCanvasValue } from './TranslateCanvasValue'

function padLeft (str, n) {
  let result = str
  while (result.length < n) { result = '0' + result }
  return result
}

class Map {
  constructor (mapId, mapWz, mapImg, $engine) {
    this.id = mapId
    this.loading = Promise.all([
      mapImg.resolve('info').then(this.ParseInfo.bind(this))
        .catch(err => {
          console.error(`Couldn't load info for map ${mapId} -- ${err}`)
        }),
      mapImg.resolve('portal').then(this.ParsePortals.bind(this))
        .catch(err => {
          console.error(`Couldn't load portals for map ${mapId} -- ${err}`)
        }),
      mapImg.resolve('life').then(this.ParseLife.bind(this))
        .catch(err => {
          console.error(`Couldn't load life for map ${mapId} -- ${err}`)
        }),
      mapImg.resolve('foothold').then(this.ParseFootholds.bind(this))
        .catch(err => {
          console.error(`Couldn't load footholds for map ${mapId} -- ${err}`)
        }),
      mapImg.resolve('ladderRope').then(this.ParseLadderRope.bind(this))
        .catch(err => {
          console.error(`Couldn't load ladders for map ${mapId} -- ${err}`)
        }),
      mapImg.resolve('back').then(this.ParseBackgrounds.bind(this))
        .catch(err => {
          console.error(`Couldn't load backgrounds for map ${mapId} -- ${err}`)
        }),
      mapImg.resolve('seat').then(seatsNode => {
        this.seats = !seatsNode ? [] : seatsNode.children.map(seat => seat.value)
      })
        .catch(err => {
          console.error(`Couldn't load seats for map ${mapId} -- ${err}`)
        }),
      Promise.resolve(mapImg.children).then(children => {
        this.layers = children.map(child => {
          const layerIndex = Number(child.name)
          if (Number.isNaN(layerIndex)) return null

          return new MapLayer(this, mapWz, child, $engine)
        }).filter(layer => layer)
      })
    ]).then(() => {
      if (this.info) {
        this.width = this.info.VRRight - this.info.VRLeft
        this.height = this.info.VRBottom - this.info.VRTop
      }
      this.readyAt = new Date()

      if ($engine && $engine.ctx) return this.LoadBackgrounds(mapWz, $engine)
    })
  }

  RenderBackgrounds ($engine, left, top, right, bottom) {
    // See: https://github.com/NoLifeDev/NoLifeStory/blob/f73fb8fd18565e74ead10c5e2352e470e1e4ab5f/src/client/sprite.cpp
    const now = Date.now()
    const centerX = left + ((right - left) / 2); const centerY = top + ((bottom - top) / 2)
    const width = (right - left); const height = (bottom - top)

    for (let i = 0; i < this.backgrounds.length; ++i) {
      let background = this.backgrounds[i]
      if (!background || !background.renderable || background.hide) continue
      let dx = background.x - background.renderable.frame.origin.x
      let dy = background.y - background.renderable.frame.origin.y
      let {rx, ry} = background
      let shiftx = (!rx ? centerX : ((100 + rx) / 100) * (width < this.width ? (centerX) : 0))
      let shifty = (!ry ? centerY : ((100 + ry) / 100) * (height < this.height ? (centerY) : 0))
      let tileX = false; let tileY = false
      let cx = background.cx; let cy = background.cy

      switch (background.type) {
        case 0:
          dx += shiftx
          dy += shifty
          break
        case 1:
          tileX = true
          dx += shiftx
          dy += shifty
          break
        case 2:
          tileY = true
          dx += shiftx
          dy += shifty
          break
        case 3:
          tileX = true
          tileY = true
          dx += shiftx
          dy += shifty
          break
        case 4:
          tileX = true
          dx += ((now / 1000) * rx * 5) - centerX
          dy += shifty
          break
        case 5:
          tileY = true
          dx += shiftx
          dy += ((now / 1000) * ry * 5) - centerY
          break
        case 6:
          tileX = true
          tileY = true
          dx += ((now / 1000) * rx * 5) - centerX
          dy += shifty
          break
        case 7:
          tileX = true
          tileY = true
          dx += shiftx
          dy += ((now / 1000) * ry * 5) - centerY
          break
      }

      if (!cx) cx = background.renderable.frame.width
      else if (cx < 0) cx = -cx
      if (!cy) cy = background.renderable.frame.height
      else if (cy < 0) cy = -cy

      let xbegin = dx
      let xend = dx
      let ybegin = dy
      let yend = dy
      let lostPrecisionX = 0
      let lostPrecisionY = 0

      if (tileX) {
        xbegin += background.renderable.frame.width
        xbegin -= left
        xbegin %= cx
        if (xbegin <= 0) xbegin += cx
        xbegin -= background.renderable.frame.width
        xbegin += left
        lostPrecisionX = xbegin
        xbegin = Math.round(xbegin)
        lostPrecisionX -= xbegin

        xend -= right
        xend %= cx
        xend += right
        xend = Math.round(xend)

        if (xend < xbegin) {
          continue
        }
      }

      if (tileY) {
        ybegin += background.renderable.frame.height
        ybegin -= top
        ybegin %= cy
        if (ybegin <= 0) ybegin += cy
        ybegin -= background.renderable.frame.height
        ybegin += top
        lostPrecisionY = ybegin
        ybegin = Math.round(ybegin)
        lostPrecisionY -= ybegin

        yend -= bottom
        yend %= cy
        yend += bottom
        yend = Math.round(yend)

        if (yend < ybegin) {
          continue
        }
      }

      if (xend + background.renderable.frame.width < left) {
        continue
      }
      if (xbegin > right) {
        continue
      }
      if (yend + background.renderable.frame.height < top) {
        continue
      }
      if (ybegin > bottom) {
        continue
      }

      xbegin += lostPrecisionX
      xend += lostPrecisionX
      ybegin += lostPrecisionY
      yend += lostPrecisionY

      for (let x = xbegin; x <= xend; x += cx) {
        for (let y = ybegin; y <= yend; y += cy) {
          $engine.drawImage(
            background.renderable.frame,
            x,
            y
          )
        }
      }
    }
  }

  Render ($engine, left, top, right, bottom) {
    this.RenderBackgrounds($engine, left, top, right, bottom)
    this.layers.forEach(layer => layer.Render($engine, left, top, right, bottom))
  }

  RenderBGMVisual (canvas, canvasCtx) {
    if (!this.analyserData) { this.analyserData = new Uint8Array(this.bgmNode.analyser.frequencyBinCount) }
    this.bgmNode.analyser.getByteFrequencyData(this.analyserData)

    const capFrequencyCount = this.analyserData.length / 2
    const { width, height } = canvas

    const visualWidth = capFrequencyCount
    const spaceBetween = 2
    const perX = (visualWidth * (spaceBetween + 1)) / width

    let thisAvg = 0
    let k = 0
    this.analyserData.forEach((level, i) => {
      if (!level) return
      if (i > capFrequencyCount) return
      thisAvg += level

      if ((i + 1) % perX < 1) {
        level = thisAvg / perX
      } else return

      ++k
      let x = (spaceBetween + 1) * k
      let lineHeight = (((thisAvg / perX) / 255) * height)

      canvasCtx.beginPath()
      canvasCtx.moveTo(x, height)
      canvasCtx.lineTo(x, height - lineHeight)
      canvasCtx.closePath()
      canvasCtx.stroke()

      thisAvg = 0
    })
  }

  Update (delta) {
    this.backgrounds.forEach(background => background.renderable ? background.renderable.Update(delta) : null)
    this.layers.forEach(layer => layer.Update(delta))
  }

  LoadBackgrounds (mapWz, $engine) {
    return Promise.all(
      this.backgrounds.map(background => {
        return mapWz.resolve(`Back/${background.path}`)
          .then(objCanvas => objCanvas || (mapWz.resolve(`../Map2/Back/${background.path}`)))
          .then(objCanvas => objCanvas || (mapWz.resolve(`../Map001/Back/${background.path}`)))
          .then(backNode => {
            background.canvasNode = backNode
            return TranslateCanvasValue(this, background, $engine)
          })
      })
    ).then(backgrounds => {
      this.backgrounds.sort((a, b) => a.id - b.id)
      return backgrounds
    })
  }

  ParseBackgrounds (backgroundsNode) {
    if (!backgroundsNode) {
      this.backgrounds = []
      return
    }

    this.backgrounds = backgroundsNode.children.map(backgroundNode => {
      const result = {
        id: Number(backgroundNode.name)
      }

      backgroundNode.children.forEach(prop => {
        if (prop.name === 'no') (result.backgroundSet || (result.backgroundSet = {})).id = prop.value
        else if (prop.name === 'bS') (result.backgroundSet || (result.backgroundSet = {})).name = prop.value
        else if (prop.name === 'front') result.front = prop.value
        else if (prop.name === 'a') result.alpha = (prop.value || 255) / 255
        else if (prop.name === 'f') result.flipX = prop.value
        else if (prop.name === 'type') result.type = prop.value
        else if (prop.name === 'x') result.x = prop.value
        else if (prop.name === 'y') result.y = prop.value
        else if (prop.name === 'rx') result.rx = prop.value
        else if (prop.name === 'ry') result.ry = prop.value
        else if (prop.name === 'cx') result.cx = prop.value
        else if (prop.name === 'cy') result.cy = prop.value
        else if (prop.name === 'ani') result.animated = prop.value
        else console.warn('Unknown property: ', prop.name, prop)
      })

      result.path = `${result.backgroundSet.name}/back/${result.backgroundSet.id}`.replace(' ', '')

      return result
    })
  }

  ParseInfo (infoNode) {
    console.log('info:', infoNode)
    this.info = {}
    if (!infoNode) return
    infoNode.children.forEach(child => {
      this.info[child.name] = child.value
    })

    let wz = infoNode
    while (wz.parent != null) { wz = wz.parent }

    if (this.info.bgm) {
      const bgmPath = this.info.bgm.split('/')
      const folder = bgmPath[0]
      const fileName = bgmPath[1]
      return wz.resolve(`Sound/${folder}.img/${fileName}`).then(audioNode => {
        this.bgmNode = audioNode
      })
    }
  }

  StopBGM () {
    if (this.bgmNode.playPromise) { return this.bgmNode.playPromise.then(() => this.bgmNode.stop()) }

    this.bgmNode.stop()
  }

  PlayBGM (loop, onEnd) {
    if (this.bgmNode.playPromise) {
      return this.bgmNode.playPromise.then(() => {
        this.bgmNode.stop()
        return this.bgmNode.play(loop, onEnd)
      })
    }

    this.bgmNode.stop()
    return this.bgmNode.play(loop, onEnd)
  }

  ParsePortals (portalsNode) {
    if (!portalsNode) {
      this.portals = []
      return
    }
    this.portals = portalsNode.children.map(portalNode => {
      const portalResult = { }

      portalNode.children.forEach(prop => {
        if (prop.name === 'pn') portalResult.name = prop.value
        if (prop.name === 'tm') (portalResult.target || (portalResult.target = {})).mapId = prop.value
        if (prop.name === 'tn') (portalResult.target || (portalResult.target = {})).name = prop.value
        if (prop.name === 'x') portalResult.x = prop.value
        if (prop.name === 'y') portalResult.y = prop.value
        if (prop.name === 'image') portalResult.name = prop.value // TODO: Verify this
        if (prop.name === 'onlyOnce') portalNode.onlyOnce = prop.value
      })

      return portalResult
    })
  }

  ParseLife (lifeNodes) {
    if (!lifeNodes) {
      this.lifeTemplates = []
      return
    }
    this.lifeTemplates = lifeNodes.children.map(lifeNode => {
      const lifeResult = {}

      lifeNode.children.forEach(prop => {
        if (prop.name === 'x') lifeResult.x = prop.value
        if (prop.name === 'y') lifeResult.y = prop.value
        if (prop.name === 'rx0') (lifeResult.walkArea || (lifeResult.walkArea = {})).left = prop.value
        if (prop.name === 'rx1') (lifeResult.walkArea || (lifeResult.walkArea = {})).right = prop.value
        if (prop.name === 'id') lifeResult.id = prop.value
        if (prop.name === 'fh') lifeResult.fh = prop.value
        if (prop.name === 'f') lifeResult.flipX = prop.value
        if (prop.name === 'hide') lifeResult.hidden = prop.value
        if (prop.name === 'type') lifeResult.type = prop.value
      })

      return lifeResult
    })
  }

  ParseFootholds (footholds) {
    const footholdResults = {}
    this.footholds = footholdResults
    if (!footholds) { return }
    footholds.children.forEach(layer => {
      layer.children.forEach(group => {
        group.children.forEach(fhProp => {
          const fh = footholdResults[fhProp.name] = {
            id: fhProp.name,
            group: group.name,
            layer: layer.name
          }

          fhProp.children.forEach(prop => {
            if (prop.name === 'next') fh.next = prop.value
            if (prop.name === 'prev') fh.prev = prop.value
            if (prop.name === 'piece') fh.piece = prop.value
            if (prop.name === 'x1') fh.x1 = prop.value
            if (prop.name === 'x2') fh.x2 = prop.value
            if (prop.name === 'y1') fh.y1 = prop.value
            if (prop.name === 'y2') fh.y2 = prop.value
          })
        })
      })
    })
  }

  ParseLadderRope (ladderRopeNodes) {
    if (!ladderRopeNodes) {
      this.ladderRopes = []
      return
    }

    this.ladderRopes = ladderRopeNodes.children.map(ladderRopeNode => {
      const ladderRope = {}

      ladderRopeNode.children.forEach(prop => {
        ladderRope[prop.name] = prop.value
      })

      ladderRope.IsLadder = ladderRope.l

      return ladderRope
    })
  }
}

export default class MapFactory {
  constructor (wz) {
    this.wz = wz
  }

  GetMap (mapId, $engine) {
    let mapIdStr = padLeft(mapId.toString(), 9)
    return this.wz.resolve('Map')
      .then(mapWz => mapWz || this.wz.resolve('Data/Map'))
      .then(mapWz => {
        return mapWz.resolve(`Map/Map${mapIdStr.substr(0, 1)}/${mapIdStr}.img`)
          .then(mapImg => {
            if (mapImg) { return mapImg }

            return mapWz.resolve(`../Map002/Map/Map${mapIdStr.substr(0, 1)}/${mapIdStr}.img`)
          }).then(mapImg => new Map(mapId, mapWz, mapImg, $engine))
      })
      .then(map => map.loading.then(() => map))
  }
}
