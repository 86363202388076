import Vue from 'vue'
import App from './App.vue'
import WSCallback from './registerServiceWorker'

// VueX
import store from './Store'
import Synchronizer from './Store/Synchronizer'

if (window.location.protocol === 'https:')
  WSCallback(store)
window.StateSynchronizer = new Synchronizer(store)

// Vuetify
import vuetify from './plugins/vuetify';

// Setup
if (window.location.protocol === 'http:' && window.location.hostname != 'localhost') {
  console.log('Upgrading', window.location.protocol, window.location.host, window.location.hostname)

  window.location.protocol = 'https'
}

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  vuetify,
  store
}).$mount('#app')
