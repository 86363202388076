<template>
  <div :class='{ loading: !mapReady, "canvas-container": true }'>
    <div v-if='camera' class='camera-text'>
      <div>
        <span class='dev' v-if='development'>{{camera.x}}, {{camera.y}} @ {{fps}}fps</span>
      </div>
      <audio controls :src='bgmPath' loop />
    </div>
    <canvas id='map-render' ref="renderTarget"
      :width='width'
      :height='height'
      @mousewheel="mouseWheel" />
  </div>
</template>

<script>
import Renderer from '../../MapleStory/Renderer'
import MapFactory from '../../MapleStory/maps'
import Vue from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'
// import NPC from '../../MapleStory/NPC'
// import Mob from '../../MapleStory/Mob'
import API from '../../API'

export default {
  name: 'Map.Render',

  created () {
    this.StartPreview()
  },

  destroyed() {
    this.mapReady = false
    this.map = null
    if (this.renderer)
      this.renderer.dispose()
  },

  watch: {
    id() {
      this.mapReady = false
      this.map = null
      this.StartPreview()
    },

    map(newVal) {
      if (newVal)
        Vue.nextTick(this.InitializeRenderer.bind(this))
    }
  },

  props: {
    id: Number,
    region: String,
    version: String
  },

  data: function () {
    this.renderer = null

    return {
      mouseDown: false,
      lastMouse: null,
      lastTouch: null,
      mapReady: false,
      cameraRef: null,
      fps: 0,
      ShowPreview: false,
      map: null
    }
  },

  methods: {
    ...mapActions(['SetCamera']),

    StartPreview () {
      API.Maps.Get(this.id).then(map => {
        this.map = map
      })
    },

    InitializeRenderer () {
      if (this.renderer) {
        this.renderer.Dispose()
      }
      this.renderer = new Renderer(
        this.$refs.renderTarget,
        () => this.internalCamera,
        (x, y) => this.SetCamera({
          x: this.camera.x + x,
          y: this.camera.y + y
        })
      )

      if (this.map.vrBounds) {
        this.renderer.camera.x = this.map.vrBounds.x
        this.renderer.camera.y = this.map.vrBounds.y
      }

      this.renderer.reportFPS = fps => {
        this.fps = Math.round(fps)
      }

      API.Direct.WZ(this.region, this.version)
        .then(wz => {
          let mapFactory = new MapFactory(wz)
          console.log('Loading map', this.map.id)
          return mapFactory.GetMap(this.map.id, this.renderer.$engine)
            .then(map => {
              if (map.info && map.info.link) {
                return mapFactory.GetMap(map.info.link, this.renderer.$engine)
              }
              return map
            })
        }).then(map => {
          this.mapReady = true
          this.renderer.map = map
          this.renderer.Render()
        })
        // .then(() => Promise.all([this.PopulateNPCs(), this.PopulateMobs()]))
        // .then(([npcs, mobs]) => {
        //   let renderables = [
        //     ...npcs,
        //     ...mobs
        //   ]

        //   let layered = renderables.reduceRight((total, current) => {
        //     let layer = total[current.layer]
        //     if (layer) layer.push(current)
        //     else total[current.layer] = [current]

        //     return total
        //   }, {})

        //   this.renderer.renderables = layered

        //   console.log('Renderables ready', layered)
        // })
        .catch(err => {
          console.error(err)
          console.warn('Error occurred while loading')
          throw err
        })
    },

    // PopulateNPCs () {
    //   console.log(`Populating ${this.map.npcs.length} npcs`)
    //   return Promise.all(this.map.npcs.map(npc =>
    //     this.PopulateNpc(npc.id).then(npcData => {
    //       return new NPC({
    //         ...npcData,
    //         ...npc,
    //         layer: npc.foothold ? npc.foothold.layerId : 7
    //       })
    //     }).then(npc => npc.LoadRenderables(this.renderer.$engine).then(() => npc))
    //   ))
    // },

    // PopulateMobs () {
    //   console.log(`Populating ${this.map.mobs.length} mobs`)
    //   let allMobs = []
    //   return this.map.mobs.reduce((current, mob) =>
    //     current.then(() => {
    //       return this.PopulateMob(mob.id).then(mobData => {
    //         return new Mob({
    //           ...mobData,
    //           ...mob,
    //           layer: mob.foothold ? mob.foothold.layerId : 7
    //         }, this.renderer.map)
    //       }).then(mob => mob.LoadRenderables(this.renderer.$engine)
    //       .then(() => {
    //         allMobs.push(mob)
    //       }))
    //     }),
    //     Promise.resolve()
    //   ).then(() => allMobs)
    // },

    mouseWheel (e) {
      let scaleDelta = e.wheelDelta / 1000
      console.log(scaleDelta)
      this.renderer.Scale(scaleDelta)
      e.preventDefault()
    }
  },

  computed: {
    ...mapGetters(['api']),
    ...mapState(['camera', 'development']),

    bgmPath () { return [this.api, 'map', this.id, 'bgm'].join('/') },
    position() { return { x:0,y:0 } },

    internalCamera () {
      const globalCamera = this.camera

      return {
        x: Math.round(globalCamera.x - this.position.x),
        y: Math.round(globalCamera.y - this.position.y),

        override: {
          x: this.map.vrBounds.left,
          y: this.map.vrBounds.top
        }
      }
    },

    width() {
      if (this.map && this.map.vrBounds) return this.map.vrBounds.width
      return 0
    },
    height() {
      if (this.map && this.map.vrBounds) return this.map.vrBounds.height
      return 0
    }
  }
}
</script>

<style scoped>
.dev {
  display: block;
}

.canvas-container {
  position: absolute;
}

.camera-text {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px;
  background: rgba(255, 255, 255, 0.9);
  border-bottom-left-radius: 4px;
  box-shadow: 0 0 4px black;
}
</style>