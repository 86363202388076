import Fixes from './Fixes'

export default class Synchronizer {
  constructor(store, key) {
    this.key = key || 'vuex'
    this.syncKey = this.key + '-sync'

    this.$store = store
    this.Sync()
    window.addEventListener("storage", this.Sync.bind(this))

    this.$store.subscribe(this.Mutation.bind(this))
    this.$store.subscribeAction((action, state) => {
      if (state.development) {
        console.log('VueX Action: ', action, state)
      }
    })
    this.syncInterval = setInterval(this.Write.bind(this), 100)
  }

  Write() {
    if (!this.pendingWrite) return

    const writing = {
      ...this.pendingWrite
    }

    delete this.pendingWrite
    delete writing.ServiceWorkerStatus
    delete writing.PWA

    if (writing.development) {
      console.log('Writing new state to storage', writing)
    }

    localStorage[this.key] = JSON.stringify(writing)
    localStorage[this.syncKey] = this.syncToken
  }

  Sync() {
    const existingSync = localStorage[this.syncKey]

    if (!existingSync) {
      localStorage[this.syncKey] = this.syncToken = Date.now()
      this.pendingWrite = this.$store.state
      this.Write()
      return // Use the default localStorage state
    }

    if (this.syncToken === existingSync) return

    const existingState = localStorage[this.key]

    if (!existingState) {
      this.syncTOken = Date.now()
      return
    }

    this.$store.replaceState({
      ...this.$store.state,
      ...this.ApplyFixes(JSON.parse(existingState))
    })
    this.syncToken = Date.now()
  }

  ApplyFixes(newState) {
    newState.camera.x = Math.floor(newState.camera.x)
    newState.camera.y = Math.floor(newState.camera.y)

    Object.values(newState.entities).forEach(entity => {
      const fix = Fixes[entity.type]
      if (fix) fix(entity, newState)
    })

    return newState
  }

  Mutation(mutation, state) {
    if (state.development) console.log(mutation, state)
    this.pendingWrite = state
  }

  Wipe() {
    delete localStorage[this.syncKey]
    window.location.reload()
  }
}