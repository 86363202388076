import Store from '../Store'
import WZFactory from '../WZ'
import Items from './Items'
import Maps from './Maps'
import Mobs from './Mobs'
import NPCs from './NPCs'
import Pets from './Pets'
import WZ from './WZ'

if (!window.IOAPI) {
  class Gateway {
    constructor() {
      this.$store = Store

      this.Items = new Items(this)
      this.NPCs = new NPCs(this)
      this.Mobs = new Mobs(this)
      this.Maps = new Maps(this)
      this.Pets = new Pets(this)
      this.WZ = new WZ(this)

      const wzFactory = new WZFactory(() => this.storeUrl())
      this.Direct = {
        WZ: (region, version) => wzFactory.GetWZ(region || this.$store.state.region, version || this.$store.state.version)
      }
    }

    storeUrl() {
      return this.$store.state.storeRoot
    }

    apiUrl() {
      return this.$store.getters.api
    }

    apiRootUrl() {
      return this.$store.state.apiRoot
    }

    Get(path, queryParams, root) {
      const query = new URLSearchParams(queryParams)
      const fullApiRoute = (root ? this.apiRootUrl() : this.apiUrl()) + '/' + path + '?' + query

      return fetch(fullApiRoute)
        .then(res => res.json())
    }
  }

  window.IOAPI = new Gateway()
}

export default window.IOAPI
