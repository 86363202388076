<template>
  <v-navigation-drawer
    v-model='visible'
    absolute
    temporary
    right
    class='app-settings'
    >
    <v-list>
      <v-list-item>
        <v-skeleton-loader
          type='heading'
          v-if='!wz.length'
          />
        <v-select
          :value='this.region'
          @input='UpdateRegion'
          label='Region'
          :items='regions'
          v-else
          />
      </v-list-item>
      <v-list-item>
        <v-skeleton-loader
          type='heading'
          v-if='!wz.length'
          />
        <v-select
          :value='this.version'
          @input='version => ApplySetting({version})'
          label='Version'
          :items='versions'
          v-else
          />
      </v-list-item>

      <v-list-item class='color-picker-container'>
        <v-subheader>Background Color</v-subheader>
        <v-color-picker
          elevation="4"
          v-model='backgroundColor'
          />
      </v-list-item>
      <v-list-item class='sha1'>
        Build: {{ SHA1 }}
      </v-list-item>
      <v-list-item class='sha1'>
        Version: {{ appVersion }}
      </v-list-item>
      <v-list-item class='service-worker-status' v-if='development'>
        <strong>Service Worker Status:</strong>
        <div>
          <div
            v-for='status in FriendlyServiceWorkerStatus'
            :key='status.label'
          >
            <strong>{{status.label}}</strong>: <span>{{status.value}}</span>
          </div>
        </div>
      </v-list-item>
      <v-list-item class='pwa-status' v-if='development'>
        <strong>PWA Status:</strong>
        <div>
          <div
            v-for='status in FriendlyPWAStatus'
            :key='status.label'
          >
            <strong>{{status.label}}</strong>: <span>{{status.value}}</span>
          </div>
        </div>
      </v-list-item>
      <v-list-item>
        <v-switch
          v-model='isDevMode'
          label='Developer Mode'
          />
      </v-list-item>
      <v-list-item v-if='PWA.BeforeInstallPrompt'>
        <v-btn @click='e => PWA.BeforeInstallPrompt.prompt()' block color='primary'>Install</v-btn>
      </v-list-item>
      <v-list-item v-if='ServiceWorkerStatus.Registered'>
        <v-btn @click='Update' block color='secondary'>Update</v-btn>
      </v-list-item>
      <v-list-item>
        <v-btn :href='ReloadTo' block color='secondary'>Reload</v-btn>
      </v-list-item>
      <v-list-item>
        <v-btn @click='LoadWZData' block color='secondary'>Re-fetch WZ Data</v-btn>
      </v-list-item>
      <v-list-item v-if='isDevMode'>
        <v-btn @click='Wipe' block color='red'>Clear all data</v-btn>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import API from '../API'
import DrawerNames from '@/Constants/Drawers'

export default {
  name: 'App.Settings',

  data: () => ({
    wz: [],
    wzLookup: {},
    SHA1: process.env.VUE_APP_GIT_HASH || 'Development',
    appVersion: process.env.VUE_APP_VERSION || 'Development',
  }),

  created() {
    this.LoadWZData()
  },

  methods: {
    ...mapActions(['OpenDrawer', 'ApplySetting']),

    UpdateRegion(region) {
      const versions = this.GetVersionsFor(region)
      const version = versions[0]

      this.ApplySetting({ region, version })
    },

    GetVersionsFor(region) {
      return this.wz.filter(wz => wz.region === region)
        .map(wz => wz.mapleVersionId)
        .reverse()
    },

    Update() {
      this.ServiceWorkerStatus.Registered.update()
    },

    LoadWZData() {
      API.WZ.List()
      .then(wz => {
        this.wz = wz
        this.wzLookup = wz.reduce((total, current) => {
          if (!total[current.region])
            total[current.region] = {}

          total[current.region][current.mapleVersionId] = current
          return total
        }, {})
      })
    },

    Wipe() {
      window.StateSynchronizer.Wipe()
    }
  },

  computed: {
    ...mapState(['drawerOpen', 'region', 'version', 'background', 'development', 'ServiceWorkerStatus', 'PWA']),

    visible: {
      get() { return this.drawerOpen === DrawerNames.AppSettings },
      set(value) {
        if (value && !this.visible)
          this.OpenDrawer(DrawerNames.AppSettings)
        else if (!value && this.visible)
          this.OpenDrawer(null)
      }
    },

    regions() { return Object.keys(this.wzLookup) },
    versions() { return this.GetVersionsFor(this.region) },

    isDevMode: {
      get(){ return this.development },
      set(development) { this.ApplySetting({development}) }
    },

    backgroundColor: {
      get() { return this.background },
      set(background) { this.ApplySetting({ background }) }
    },

    FriendlyServiceWorkerStatus() {
      if (!this.development) return []

      return Object.keys(this.ServiceWorkerStatus).reduce((total, current) => {
        total.push({
          label: current,
          value: JSON.stringify(this.ServiceWorkerStatus[current])
        })

        return total
      }, [])
    },

    FriendlyPWAStatus() {
      if (!this.development) return []

      return Object.keys(this.PWA).reduce((total, current) => {
        total.push({
          label: current,
          value: JSON.stringify(this.PWA[current])
        })

        return total
      }, [])
    },

    ReloadTo() {
      return `${window.location.origin}/?t=${Date.now()}`
    }
  }
}
</script>

<style lang="scss">
.app-settings {
  min-width: 350px;

  .v-navigation-drawer__content {
    padding: 0 0 16px 0;

    .v-skeleton-loader {
      width: 100%;

      .v-skeleton-loader__heading {
        width: 100%;
      }
    }
  }

  .color-picker-container {
    flex-direction: column;
    align-items: flex-start;

    .v-color-picker {
      align-self: center;
    }
  }

  .sha1 {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    justify-content: flex-start;
  }

  .pwa-status {
    strong {
      min-width: 155px;
    }
  }

  .service-worker-status {
    strong {
      min-width: 100px;
    }
  }

  .service-worker-status, .pwa-status {
    justify-content: flex-start;
    flex-direction: column;
    align-items: baseline;

    strong {
      display: inline-block;
    }

    span {
      margin-left: 4px;
    }
  }
}
</style>