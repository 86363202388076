import pako from 'pako'

export default class Reader {
  constructor (ab, startPosition, length) {
    this.ab = ab
    this.view = new DataView(ab, startPosition || 0, length || ab.byteLength)
    this.position = 0
    this.startPosition = startPosition || 0
    this.length = ab.byteLength
  }
  Advance (count) {
    const pos = this.position
    this.position += count
    return pos
  }
  ReadByte () { return this.view.getInt8(this.Advance(1)) }
  ReadUByte () { return this.view.getUint8(this.Advance(1)) }
  ReadInt16 () { return this.view.getInt16(this.Advance(2), true) }
  ReadUInt16 () { return this.view.getUint16(this.Advance(2), true) }
  ReadInt32 () { return this.view.getInt32(this.Advance(4), true) }
  ReadUInt32 () { return this.view.getUint32(this.Advance(4), true) }
  ReadInt64 () { return this.view.getBigInt64(this.Advance(8), true) }
  ReadUInt64 () { return this.view.getBigUint64(this.Advance(8), true) }
  ReadSingle () { return this.view.getFloat32(this.Advance(4), true) }
  ReadDouble () { return this.view.getFloat64(this.Advance(8), true) }
  ReadWZSingle () {
    const possible = this.ReadByte()
    if (possible === 0x80) { return this.ReadSingle() } else { return possible }
  }
  ReadWZInt () {
    const possible = this.ReadByte()
    if (possible === -128) { return this.ReadInt32() }
    return possible
  }
  ReadWZStringBlock () {
    const type = this.ReadByte()
    switch (type) {
      case 0:
        return this.ReadWZString()
      default:
        throw new Error('Not supported string')
    }
  }
  ReadWZString () {
    let length = this.ReadByte()
    const isUnicode = length > 0
    if (isUnicode) {
      if (length === 127) { length = this.ReadInt32() } else { length = length * 2 }
    } else {
      if (length === -128) { length = this.ReadInt32() } else { length = length * -1 }
    }
    let resStr = []
    if (isUnicode) {
      length = length / 2
      for (let i = 0; i < length; ++i) {
        let charAt = this.ReadUInt16()
        charAt = charAt ^ (isUnicode ? 0xAAAA : ((0xAA + i) % 255))
        resStr[i] = charAt
      }
    } else {
      for (let i = 0; i < length; ++i) {
        let charAt = this.ReadUByte()
        charAt = charAt ^ (isUnicode ? 0xAAAA : ((0xAA + i) % 255))
        resStr[i] = charAt
      }
    }
    return String.fromCharCode.apply(null, resStr)
  }
  ReadCanvas (width, height, format, imageLength) {
    if (format === 7) imageLength--

    const newCopy = new Uint8Array(this.ab, this.position + this.startPosition, imageLength)
    try {
      let inflated = null
      if (format === 4 || format === 6 || format === 7) { // 4 has zlib header, 6 has a gzip header, both use DEFLATE and pako supports both seamlessly
        inflated = pako.inflate(newCopy)
      } else if (format === 5) {
        inflated = newCopy
      } else {
        throw new Error('Not accepted format')
      }
      if (inflated.length % 4) {
        const expectedCount = width * height * 4
        const difference = expectedCount - inflated.length
        console.warn('Length isn\'t valid')
        if (difference > 0) {
          const newInflated = new Uint8Array(expectedCount)
          newInflated.set(inflated, 0)
          inflated = newInflated
        } else { inflated = inflated.subarray(0, expectedCount) }
      }
      if (inflated == null) {
        console.warn('Canvas has no image data')
      }
      return new ImageData(new Uint8ClampedArray(inflated), width, height)
    } catch (err) {
      console.warn('Error loading canvas', err)
    }
    return null
  }
}
