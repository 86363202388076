<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <v-btn icon @click='OpenCharacterList'>
        <v-icon>menu</v-icon>
      </v-btn>
      <v-toolbar-title>MapleStory Studio</v-toolbar-title>
      <v-spacer />
      <Updates />
      <v-btn icon @click='OpenAppSettings'>
        <v-icon>mdi-cog</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main class='body'>
      <CharacterEdit v-if='IsCharacterFocused' v-model='activeDrawer' />
      <CharacterList v-if='activeDrawer == DrawerNames.Character.List' />
      <Sim />
      <Speed />
      <Settings />
    </v-main>
  </v-app>
</template>

<script>
import CharacterEdit from './components/Character/Edit/index'
import Sim from './components/Sim'
import CharacterList from './components/Character/List/index'
import Speed from './components/Speed'
import Settings from './components/Settings'
import DrawerNames from '@/Constants/Drawers'
import Updates from './components/Updates'

import { mapActions, mapState } from 'vuex'

export default {
  name: 'App',

  data: () => ({ DrawerNames }),

  components: {
    CharacterEdit,
    Sim,
    CharacterList,
    Speed,
    Settings,
    Updates
  },

  methods: {
    ...mapActions(['OpenDrawer']),

    OpenCharacterList() { this.OpenDrawer(DrawerNames.Character.List) },
    OpenAppSettings() { this.OpenDrawer(DrawerNames.AppSettings) }
  },

  computed: {
    ...mapState(['drawerOpen', 'entities', 'focusedEntityId']),

    IsCharacterFocused() {
      if (!this.focusedEntityId) return false
      return this.entities[this.focusedEntityId].type === 'character'
    },

    activeDrawer: {
      get() { return this.drawerOpen },
      set(value) { this.OpenDrawer(value) }
    },
  }
}
</script>

<style lang="scss">
html, body, #app {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  min-width: 400px;
}

.v-application--wrap {
  max-height: 100%;

  .v-speed-dial--fixed {
    z-index: 15;
  }
}
</style>
