/* eslint-disable no-case-declarations */

import Property from './Property'
import ValueProperty from './ValueProperty'
import Reader from './Reader'

const USE_PROXY = (typeof window !== 'undefined' && window.Proxy !== undefined) || (Proxy !== undefined)

export default {
  Image (name, ab, parent) {
    const reader = new Reader(ab)
    const imgType = reader.ReadByte()
    if (imgType !== 0x73) return null
    const property = reader.ReadWZString()

    if (property !== 'Property') { throw new Error('Invalid first property') }

    if (reader.ReadInt16() !== 0) { throw new Error('Invalid header') }

    const img = new Property(name, 'image', [], parent)

    img.children = this.ImageChildren(img, reader)
    return img
  },

  ImageChildren (origin, reader) {
    return this.PropertyList(origin, reader)
  },

  PropertyList (origin, reader) {
    const count = reader.ReadWZInt()

    const children = []

    for (let i = 0; i < count; ++i) {
      const name = reader.ReadWZStringBlock()
      const type = reader.ReadByte()
      let prop = null
      switch (type) {
        case 0:
          prop = new ValueProperty(name, 'property', null, null, origin)
          break
        case 0x10:
          prop = new ValueProperty(name, 'sbyte', reader.ReadByte(), null, origin)
          break
        case 0x11:
          prop = new ValueProperty(name, 'byte', reader.ReadUByte(), null, origin)
          break
        case 0x0B:
        case 0x2:
        case 0x12:
          prop = new ValueProperty(name, 'ui16', reader.ReadUInt16(), null, origin)
          break
        case 3:
        case 19:
          prop = new ValueProperty(name, 'i32', reader.ReadWZInt(), null, origin)
          break
        case 4:
          prop = new ValueProperty(name, 'float', reader.ReadWZSingle(), null, origin)
          break
        case 5:
          prop = new ValueProperty(name, 'double', reader.ReadDouble(), null, origin)
          break
        case 8:
          prop = new ValueProperty(name, 'string', reader.ReadWZStringBlock(), null, origin)
          break
        case 9:
          const blockLength = reader.ReadUInt32()
          const newPosition = reader.position + blockLength

          if (USE_PROXY) {
            const subReader = new Reader(reader.ab.slice(reader.position, blockLength + reader.position), 0, blockLength)
            prop = this.ExtendedPropertyProxy(origin, name, subReader)
          } else prop = this.ExtendedProperty(origin, name, reader)

          reader.position = newPosition
          break
        case 20:
          prop = new ValueProperty(name, 'i64', reader.ReadInt64(), null, origin)
          break
        case 21:
          prop = new ValueProperty(name, 'ui64', reader.ReadUInt64(), null, origin)
          break
        default:
          throw new Error(`Unknown property type: ${type}`)
      }

      children.push(prop)
    }

    return children
  },

  ExtendedPropertyProxy (origin, name, reader) {
    const propertyType = reader.ReadWZStringBlock()

    let valType = ''
    switch (propertyType) {
      case 'Property':
        valType = 'property'
        break
      case 'Canvas':
        valType = 'canvas'
        break
      case 'Shape2D#Vector2D':
        valType = 'vector'
        break
      case 'Shape2D#Convex2D':
        valType = 'convex'
        break
      case 'Sound_DX8':
        valType = 'sound'
        break
      case 'UOL':
        valType = 'uol'
        break
      default:
        throw new Error(`Unknown extended property type: ${propertyType}`)
    }

    const fakeProp = new ValueProperty(name, valType, 'Access `children` or `value` programatically to parse this property', [], origin)
    fakeProp.uninitialized = true

    const that = this
    const proxyProp = new Proxy(fakeProp, {
      get (obj, prop) {
        if ((prop === 'value' || prop === 'children' || !(prop in obj)) && obj.uninitialized) {
          const realValue = that.ExtendedPropertyValue(origin, name, reader, propertyType)
          Object.assign(obj, realValue)
          for (let propName in obj) {
            if (obj[propName] instanceof Function) {
              obj[propName] = obj[propName].bind(obj)
            }
          }
          delete obj.uninitialized
        }

        if (prop in obj) return obj[prop]
      }
    })

    return proxyProp
  },

  ExtendedProperty (origin, name, reader) {
    const propertyType = reader.ReadWZStringBlock()

    return this.ExtendedPropertyValue(origin, name, reader, propertyType)
  },

  ExtendedPropertyValue (origin, name, reader, propertyType) {
    switch (propertyType) {
      case 'Property':
        reader.ReadUInt16() // Blank
        const propertyNode = new ValueProperty(name, 'property', undefined, [], origin)
        propertyNode.children = this.PropertyList(propertyNode, reader)
        return propertyNode
      case 'Canvas':
        reader.ReadByte() // Unknown
        const hasChildren = reader.ReadByte()

        const prop = new ValueProperty(name, 'canvas', null, [], origin)
        if (hasChildren) {
          reader.ReadInt16()
          prop.children = this.PropertyList(prop, reader)
        }

        const width = reader.ReadWZInt()
        const height = reader.ReadWZInt()
        const format1 = reader.ReadWZInt()
        const format2 = reader.ReadByte()
        reader.ReadInt32() // Unknown
        const blockLength = reader.ReadInt32()
        reader.ReadByte() // Unknown
        const header = reader.ReadInt16()
        reader.position -= 2

        prop.value = reader.ReadCanvas(width, height, format1 + format2, blockLength)

        prop.width = width
        prop.height = height
        prop.format = format1 + format2
        prop.format1 = format1
        prop.format2 = format2
        prop.blockLength = blockLength
        prop.header = header

        return prop
      case 'Shape2D#Vector2D':
        return new ValueProperty(name, 'vector', { x: reader.ReadWZInt(), y: reader.ReadWZInt() }, null, origin)
      case 'Shape2D#Convex2D':
        const convexCount = reader.ReadWZInt()
        const convexChildren = []
        const convexProp = new ValueProperty(name, 'convex', undefined, convexChildren, origin)
        for (let i = 0; i < convexCount; ++i) { convexChildren.push(this.ExtendedProperty(convexProp, i, reader)) }
        return convexProp
      case 'Sound_DX8':
        reader.ReadByte() // Unk
        reader.ReadWZInt() // Length
        reader.ReadWZInt() // Duration
        const audioProp = new ValueProperty(name, 'sound', reader.ab, null, origin)

        audioProp.play = function (loop, onEnd) {
          const audioCtx = new AudioContext()
          const audioSrc = audioCtx.createBufferSource()
          const audioAnalyser = audioCtx.createAnalyser()

          this.playPromise = audioCtx.decodeAudioData(
            this.value.slice(0, this.length),
            (audioBuffer) => {
              this.analyser = audioAnalyser
              audioSrc.connect(audioCtx.destination)
              audioSrc.connect(audioAnalyser)
              audioSrc.buffer = audioBuffer
              this.audio = audioSrc
              if (onEnd) { this.audio.onended = onEnd }
              if (loop) { this.audio.loop = loop }
              this.audio.start(0)
              this.playing = audioSrc.context.state !== 'suspended'
            })

          return this.playPromise
        }

        audioProp.stop = function () {
          if (this.audio) {
            this.playing = false
            this.audio.stop(0)
          }
        }

        return audioProp
      case 'UOL':
        reader.ReadByte() // Unk
        return new ValueProperty(name, 'uol', reader.ReadWZStringBlock(), null, origin)
      default:
        throw new Error(`Unknown extended property type: ${propertyType}`)
    }
  }
}
