import RendererEngine from './RendererEngine'

export default class Renderer {
  constructor (target, camera, move) {
    if (typeof target === 'string') {
      this.canvasId = target
      this.canvas = document.querySelector(`canvas#${this.canvasId}`)
    } else if (typeof target === 'function') { this.canvas = target() } else this.canvas = target

    this.gl = this.canvas.getContext('webgl', {
      alpha: false
    })
    this.$engine = new RendererEngine(this.gl)
    this.desiredFPS = 20 // aim for 50 FPS, land on 30??? Not sure.

    this.camera = camera || {
      x: 0, y: 0
    }
    this.moveCallback = move

    this.scale = 1

    this.$engine.cameraX = 0
    this.$engine.cameraY = 0

    this.elapsedTimes = []
    this.renderables = { }
  }

  GetCamera() {
    if ((typeof this.camera) === 'function') return this.camera()
    else return this.camera
  }

  Dispose () {
    this.dispose = true
  }

  Move (x, y) {
    if (this.moveCallback) {
      return this.moveCallback(x, y)
    } else if ((typeof this.camera) === 'function') throw new Error('Can\'t mutate camera directly')

    this.camera.x += (x / this.scale)
    this.camera.y += (y / this.scale)
  }

  Scale (delta) {
    this.scale = Math.max(0.1, this.scale + delta)
  }

  Render () {
    if (this.dispose) return

    const now = Date.now()
    const elapsed = now - this.then
    const camera = this.GetCamera()

    const { gl, map, $engine, desiredFPS } = this

    if (desiredFPS && elapsed < desiredFPS) {
      requestAnimationFrame(this.Render.bind(this))
      return
    }

    this.elapsedTimes.push(elapsed)
    let allElapsed = this.elapsedTimes.reduceRight((total, current) => total + current, 0)
    let avgElapsed = allElapsed / this.elapsedTimes.length
    this.reportedFPS = 1000 / avgElapsed
    if (this.reportFPS) this.reportFPS(this.reportedFPS)

    if (this.elapsedTimes.length > 30) { this.elapsedTimes.shift() }

    this.then = now

    // eslint-disable-next-line no-undef
    // webglUtils.resizeCanvasToDisplaySize(gl.canvas)
    if (gl instanceof WebGLRenderingContext) {
      gl.viewport(0, 0, gl.canvas.width, gl.canvas.height)
      gl.enable(gl.BLEND)
      gl.blendFunc(gl.ONE, gl.ONE_MINUS_SRC_ALPHA)
      // eslint-disable-next-line no-undef
      $engine.matrix = m4.orthographic(0, this.canvas.width, this.canvas.height, 0, -1, 1)
      // TODO: Support for real mouse position -> render mouse position
      // eslint-disable-next-line no-undef
      $engine.matrix = m4.multiply($engine.matrix, m4.scaling(this.scale, this.scale, this.scale))
      $engine.preRender(gl)
      $engine.cameraX = camera.x
      $engine.cameraY = camera.y
    } else {
      const camera = this.camera

      if (this.lastCamera) {
        this.Move(this.lastCamera.x, this.lastCamera.y)
      }

      $engine.cameraX += camera.x
      $engine.cameraY += camera.y
      this.lastCamera = camera
      gl.clearRect(0, 0, this.canvas.width, this.canvas.height)
      gl.translate(-camera.x, -camera.y)
    }

    let left = $engine.cameraX
    let top = $engine.cameraY
    let right = $engine.cameraX + (this.canvas.width / this.scale)
    let bottom = $engine.cameraY + (this.canvas.height / this.scale)

    if (map) {
      map.RenderBackgrounds($engine, left, top, right, bottom)

      // const characterGroups = utilities.groupBy(characters.filter(c => c.mapId == map.id), 'layer')

      if (camera.override) {
        $engine.cameraX = camera.override.x
        $engine.cameraY = camera.override.y

        left = $engine.cameraX
        top = $engine.cameraY
        right = $engine.cameraX + (this.canvas.width / this.scale)
        bottom = $engine.cameraY + (this.canvas.height / this.scale)
      }

      // Object.keys(characterGroups).filter(c => Number(c) < 0).forEach(lowerGroup => RenderCharacters(characterGroups[lowerGroup], $engine, left, top, right, bottom))
      let that = this
      map.layers.forEach((layer, i) => {
        layer.Render($engine, left, top, right, bottom)
        let renderables = that.renderables[i]
        if (renderables) {
          renderables
            .forEach(renderable => {
              if (renderable.Render)
                renderable.Render($engine, left, top, right, bottom)
              if (renderable.Update)
                renderable.Update(elapsed)
            })
        }
        // if (characterGroups[i])
        //   RenderCharacters(characterGroups[i], $engine, left, top, right, bottom)
      })
      // Object.keys(characterGroups).filter(c => Number(c) > 6).forEach(lowerGroup => RenderCharacters(characterGroups[lowerGroup], $engine, left, top, right, bottom))

      map.Update(elapsed)
      // characters.forEach(character => character.ready && character.Update(elapsed))
    }

    requestAnimationFrame(this.Render.bind(this))
  }
}
