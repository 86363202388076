function FindAndResolve (children, pathSplit, thisChild) {
  var child = children.find(child =>
    child && (
      child.name === thisChild ||
    (
      child.name.endsWith('.img') &&
        child.name.substr(0, child.name.length - 4) === thisChild
    ))
  )
  if (child && pathSplit.length) { return child.resolve(pathSplit) } else { return child }
}

function HandleInlink (that, inlink) {
  if (!inlink) return that

  const inlinkTarget = inlink.value
  if (!inlinkTarget) return that

  let imgContainer = that
  while (imgContainer && imgContainer.type !== 'image') { imgContainer = imgContainer.parent }

  if (!imgContainer) return that

  return imgContainer.resolve(inlinkTarget).then(found => found ? found.resolve() : null)
}

function HandleOutlink (that, outlink) {
  if (!outlink) return that

  const outlinkTarget = outlink.value
  if (!outlinkTarget) return that

  let wzContainer = that
  while (wzContainer && wzContainer.parent != null) { wzContainer = wzContainer.parent }

  if (!wzContainer) return that

  return wzContainer.resolve(outlinkTarget).then(found => {
    if (!found) return wzContainer.resolve('Data/' + outlinkTarget).then(foundData => foundData ? foundData.resolve() : null)
    else return found.resolve()
  })
}

export default class Property {
  constructor (name, type, children, parent) {
    if (children !== undefined && children !== null && children.length === undefined) {
      console.warn(this)
      throw new Error('What')
    }

    this.name = name
    this.type = type
    this.children = children
    this.parent = parent
  }

  resolve (path) {
    if (!path) {
      if (this.type === 'uol') {
        const result = this.parent.resolve(this.value)
        return result
      }

      if (this.type === 'canvas') {
        const inlink = this.children.find(child => child.name === '_inlink')
        if (inlink) return HandleInlink(this, inlink)

        const outlink = this.children.find(child => child.name === '_outlink')
        if (outlink) return HandleOutlink(this, outlink)

        return this
      }

      return this
    }

    const pathSplit = Array.isArray(path) ? path : path.split(/[/\\]/g)

    const thisChild = pathSplit.shift()

    if (thisChild === '..') return this.parent.resolve(pathSplit)

    const children = this.children
    if (!children) return null

    if (children.then) { // If children returns a promise, wait for it and then continue resolving
      return children.then(realChildren => FindAndResolve(realChildren, pathSplit, thisChild))
    } else {
      return new Promise((resolve) => resolve(FindAndResolve(children, pathSplit, thisChild)))
    }
  }
}
